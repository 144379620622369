import {Box} from '@chakra-ui/react';
import {keyframes} from '@emotion/react';
import styled from '@emotion/styled/macro';

import {FaSpinner} from 'react-icons/fa';

const spin = keyframes({
  '0%': {transform: 'rotate(0deg)'},
  '100%': {transform: 'rotate(360deg)'},
});

const Spinner = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`,
});

Spinner.defaultProps = {
  'aria-label': 'loading',
};

export function FullPageSpinner(): JSX.Element {
  return (
    <Box
      fontSize="4em"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner />
    </Box>
  );
}
